'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var createStore = require('zustand/vanilla');
var react = require('react');
var useSyncExternalStoreExports = require('use-sync-external-store/shim/with-selector');

var useSyncExternalStoreWithSelector = useSyncExternalStoreExports.useSyncExternalStoreWithSelector;
function useStore(api, selector, equalityFn) {
  if (selector === void 0) {
    selector = api.getState;
  }
  var slice = useSyncExternalStoreWithSelector(api.subscribe, api.getState, api.getServerState || api.getState, selector, equalityFn);
  react.useDebugValue(slice);
  return slice;
}
var createImpl = function createImpl(createState) {
  var api = typeof createState === 'function' ? createStore(createState) : createState;
  var useBoundStore = function useBoundStore(selector, equalityFn) {
    return useStore(api, selector, equalityFn);
  };
  Object.assign(useBoundStore, api);
  return useBoundStore;
};
var create = function create(createState) {
  return createState ? createImpl(createState) : createImpl;
};
var create$1 = create;

exports.createStore = createStore;
exports.default = create$1;
exports.useStore = useStore;
Object.keys(createStore).forEach(function (k) {
  if (k !== 'default' && !exports.hasOwnProperty(k)) Object.defineProperty(exports, k, {
    enumerable: true,
    get: function () { return createStore[k]; }
  });
});
